<template>
  <v-dialog v-model="localDialog" persistent width="600" class="pa-8">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <span class="title font-weight-bold text-textblack">
          Añadir fondos
        </span>
        <v-btn icon @click="closeDialog">
          <v-icon color="#2E404E">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-6">
        <v-sheet rounded="lg" color="#F7F8F9" class="pa-13">
          <div class="mb-4">
            <div class="subtitle-1 font-weight-bold text-textblack">
              Detalle
            </div>
            <v-divider></v-divider>
          </div>

          <div class="my-2 pl-3 pr-8">
            <v-row
              v-for="(value, key) in formattedData"
              :key="key"
              align="center"
              justify="space-between"
              class="mb-4"
            >
              <div class="body-1 modalTextAddFounds">{{ key }}</div>
              <div class="subtitle-2 font-weight-bold text-textblack">
                {{ value }}
              </div>
            </v-row>
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      }
    },
    formattedData() {
      return {
        "Account number": this.data.account_number,
        "Bank name": this.data.bank_name,
        Beneficiary: this.data.beneficiary,
        Clabe: this.data.clabe,
        "Routing code": this.data.routing_code,
        "Bank address": this.data.bank_address
      };
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.text-textblack {
  color: #302e2e;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
